/*
 * @Descripttion:
 * @version:
 * @Author: 高涛
 * @Date: 2021-11-04 09:10:42
 * @LastEditors: 高涛
 * @LastEditTime: 2021-12-16 00:11:56
 */
import Vue from 'vue'
import app from './app.vue'
import router from './router'
import store from './store'

// import isInIcestark from '@ice/stark-app/lib/isInIcestark'
// import setLibraryName from '@ice/stark-app/lib/setLibraryName'
import { isInIcestark, setLibraryName } from '@ice/stark-app'

// layout
import '@/plugins/layout'

// vue-cookies
import '@/plugins/cookies'

// ant-designe-vue
import '@/plugins/antd-ui'

// v-viewer 图片查看
import '@/plugins/v-viewer'

// vcolorpicker 颜色选择
import '@/plugins/vcolorpicker'

// 富文本编辑
import '@/plugins/utils'

Vue.config.productionTip = false

let vue

// 注意：`setLibraryName` 的入参需要与 webpack 工程配置的 output.library 保持一致
setLibraryName('jingy-web-saas-rms')

// const createVue = (container) => {
//   return new Vue({
//     router,
//     store,
//     render: (h) => h(app)
//   }).$mount(container)
// }

export function mount (props) {
  console.log('----mount----')
  // const { container } = props
  // vue = createVue(container)
  // // for vue don't replace mountNode
  // container.innerHTML = ''
  // container.appendChild(vue.$el)
  const { container } = props
  console.log('container', container)
  vue = new Vue({
    router,
    store,
    mounted: () => {
      console.log('App mounted')
    },
    destroyed: () => {
      console.log('App destroyed')
    },
    render: h => h(app)
  }).$mount()

  // for vue don't replace mountNode
  container.innerHTML = ''
  container.appendChild(vue.$el)
}

export function unmount () {
  // vue && vue.$destroy()
  if (vue) vue.$destroy()
  vue.$el.innerHTML = ''
  vue = null
}

if (!isInIcestark()) {
  // createVue('#app')
  vue = new Vue({
    router,
    store,
    el: '#app',
    mounted: () => {
      console.log('App mounted')
    },
    destroyed: () => {
      console.log('App destroyed')
    },
    render: h => h(app)
  })
}
