export const Err = ({
  unknown: '系统异常，请联系技术',
  illegal: '非法操作，已跳转页面'
})

export const Pagination = ({
  pageSizes: ['10', '20', '40', '50'],
  pageSize: 10,
  layout: 'total, sizes, prev, pager, next, jumper'
})

// 资源来源
export const RoleDsType = ({
  0: '全部',
  1: '自定义',
  2: '本级及子级',
  3: '本级'
})
