/*
 * @Descripttion:
 * @version:
 * @Author: 高涛
 * @Date: 2021-10-25 14:48:34
 * @LastEditors: 高涛
 * @LastEditTime: 2021-11-17 17:37:42
 */
import {
  ConfigProvider,
  Row,
  Col,
  Icon,
  Form,
  FormModel,
  Button,
  Input,
  Select,
  Menu,
  Table,
  Dropdown,
  Modal,
  Divider,
  Spin,
  DatePicker,
  Radio,
  Upload,
  Avatar,
  Carousel,
  TreeSelect,
  Tabs,
  Collapse,
  Tag,
  Tree,
  Breadcrumb,
  Pagination,
  Popconfirm,
  InputNumber,
  PageHeader,
  Card,
  Checkbox,
  Space,
  Popover,
  Tooltip,
  Result,
  Switch,
  Cascader
} from 'ant-design-vue'
Vue.component(Cascader.name, Cascader)
Vue.component(Switch.name, Switch)
Vue.component(Result.name, Result)
Vue.component(Tooltip.name, Tooltip)
Vue.component(Space.name, Space)
Vue.component(Checkbox.name, Checkbox)
Vue.component(Checkbox.Group.name, Checkbox.Group)
Vue.component(Card.Meta.name, Card.Meta)
Vue.component(Card.name, Card)
Vue.component(PageHeader.name, PageHeader)
Vue.component(InputNumber.name, InputNumber)
Vue.component(Popconfirm.name, Popconfirm)
Vue.component(Pagination.name, Pagination)
Vue.component(Breadcrumb.name, Breadcrumb)
Vue.component(Breadcrumb.Item.name, Breadcrumb.Item)
Vue.component(Tree.name, Tree)
Vue.component(Tag.name, Tag)
Vue.component(Collapse.name, Collapse)
Vue.component(Collapse.Panel.name, Collapse.Panel)
Vue.component(TreeSelect.name, TreeSelect)
Vue.component(Carousel.name, Carousel)
Vue.component(Avatar.name, Avatar)
Vue.component(Upload.name, Upload)
Vue.component(Upload.Dragger.name, Upload.Dragger)
Vue.component(Radio.name, Radio)
Vue.component(Radio.Group.name, Radio.Group)
Vue.component(Radio.Button.name, Radio.Button)
Vue.component(DatePicker.name, DatePicker)
Vue.component(DatePicker.RangePicker.name, DatePicker.RangePicker)
Vue.component(ConfigProvider.name, ConfigProvider)
Vue.component(Row.name, Row)
Vue.component(Col.name, Col)
Vue.component(Icon.name, Icon)
Vue.component(Form.name, Form)
Vue.component(Form.Item.name, Form.Item)
Vue.component(FormModel.name, FormModel)
Vue.component(FormModel.Item.name, FormModel.Item)
Vue.component(Button.name, Button)
Vue.component(Button.Group.name, Button.Group)
Vue.component(Input.name, Input)
Vue.component(Input.Search.name, Input.Search)
Vue.component(Input.TextArea.name, Input.TextArea)
Vue.component(Select.name, Select)
Vue.component(Select.Option.name, Select.Option)
Vue.component(Select.OptGroup.name, Select.OptGroup)
Vue.component(Menu.name, Menu)
Vue.component(Menu.Item.name, Menu.Item)
Vue.component(Menu.SubMenu.name, Menu.SubMenu)
Vue.component(Menu.Divider.name, Menu.Divider)
Vue.component(Table.name, Table)
Vue.component(Dropdown.name, Dropdown)
Vue.component(Dropdown.Button.name, Dropdown.Button)
// Vue.component(Modal.name, Modal)
Vue.use(Modal)
Vue.component(Divider.name, Divider)
Vue.component(Spin.name, Spin)
Vue.component(Tabs.name, Tabs)
Vue.component(Tabs.TabPane.name, Tabs.TabPane)
Vue.component(Popover.name, Popover)
