<!--
 * @Descripttion:
 * @version:
 * @Author: 高涛
 * @Date: 2021-10-25 14:48:34
 * @LastEditors: 高涛
 * @LastEditTime: 2021-11-16 12:20:56
-->
<template>
  <div id="app" ref="app" :style="{'height':mainHeight,'overflow':'scroll','padding':'0 20px'}" v-cloak>
    <a-config-provider :locale="zhCN">
      <component :is="layout" v-show="isShow"></component>
    </a-config-provider>
  </div>
</template>

<script>
import Vuex from 'vuex'
import { getQueryString } from '@/utils'
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'

import isInIcestark from '@ice/stark-app/lib/isInIcestark'
import { store } from '@ice/stark-data'
// import store from '@/store'

export default {
  provide () {
    return {
      appRefs: this.$refs,
      show: this.show,
      reload: this.reload
    }
  },
  data () {
    return {
      mainHeight: '0px',
      zhCN,
      isShow: false,
      isRouterAlive: false
    }
  },
  computed: {
    ...Vuex.mapState([
      // 'isShow', // 是否显示
      'status' // 页面状态
    ]),
    layout () {
      return 'layout-' + (this.$route.meta.layout || 'default')
    }
  },
  watch: {
    '$route.meta.layout' (val, oldVal) {
      console.log('watch $route.meta.layout', val, oldVal, typeof val)
      if (val && !oldVal && val !== '404') {
        // this.setIsShow(true)
        this.isShow = true
      }
    }
  },
  created () {
    if (isInIcestark()) {
      // 监听用户变化
      store.on('user', user => {
        console.log('微应用监听User', user)
        this.$store.commit('SET_USER', user)
      }, true)
    } else {
      const userStr = sessionStorage.getItem('user')
      userStr && this.$store.commit('SET_USER', JSON.parse(userStr))
    }
  },
  mounted () {
    // console.log('mounted $route.meta.layout', this.$route.meta.layout)
    const layout = this.$route.meta.layout
    if (layout && layout !== '404') {
      this.isShow = true
    }
    this.isShow = true
    // if (window.__POWERED_BY_QIANKUN__) {
    //   this.actions.onGlobalStateChange((state, prev) => {
    //     // state: 变更后的状态; prev 变更前的状态
    //     // console.log('子应用RMS观察者:', state, prev)
    //     if (state.user) {
    //       this.$store.commit('SET_USER', state.user)
    //     } else {
    //       this.$store.dispatch('showToast', { type: 'error', message: '登录失效，已退出登录。' })
    //     }
    //   })
    //   this.actions.setGlobalState({ a: 'a2' })
    // }
    this.mainHeight = document.documentElement.clientHeight + 'px'
    window.addEventListener('resize', () => {
      this.mainHeight = document.documentElement.clientHeight + 'px'
    })
    const tokenType = getQueryString('tokenType')
    const tokenValue = getQueryString('tokenValue')
    const currentTenantId = getQueryString('currentTenantId')
    if (tokenType && tokenValue && currentTenantId) {
      this.$store.dispatch('setUser', { user: { tokenType, tokenValue, currentTenantId } })
      let header = {
        Authorization: tokenType + ' ' + tokenValue,
        tenantId: currentTenantId
      }
      this.$store.dispatch('setHeader', { header })
    }
  },
  methods: {
    // ...Vuex.mapMutations({
    //   setIsShow: 'SET_IS_SHOW'
    // }),
    reload () {
      this.isRouterAlive = false
      this.$nextTick(() => {
        this.isRouterAlive = true
      })
    }
  }
}
</script>

<style lang="scss">
@import './assets/scss/main.scss';
@import './assets/scss/antd/index.scss';
</style>

<style>
/* 阿里 iconfont */
@import url('//at.alicdn.com/t/font_929273_1xlox7wj6o8.css');
</style>
