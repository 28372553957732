import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { message as Message, Modal } from 'ant-design-vue'

import simulate from './simulate' // 模拟
import allocation from './allocation' // 用户端首页设置
import coupon from './coupon' // 优惠券

const modules = {
  simulate,
  allocation,
  coupon
}

const state = {
  title: '电商', // document.title默认值，用于router.js中不指定title的路由显示
  status: 200, // 页面状态: 200（正常显示）、401（非法操作）、404（非前面选项的状态）
  isShow: false, // 是否显示（防止layout闪烁）
  isMask: false, // 全局遮罩（app.vue）
  isLoading: false, // 全局loading是否开启
  delayLoadingMs: 300, // 配合isLoading使用，控制操作多少毫秒后才显示loading
  maxLoadingMs: 5000, // 配合isLoading使用，控制最大显示loading毫秒
  isLoginError: false, // 是否登录错误（接口：400100-登录异常, 400101-登录过期, 400102-未登录）
  user: {}, // 用户
  header: {}, // 请求头  token
  isExpandMenu: true, // 是否展开菜单
  permission: {}, // 权限
  currRoute: {}, // 当前路由信息
  formSearch: {}
}

const getters = {
  http: ({ dispatch }) => {
    return $http
  },
  token () { // 登录token，如果无值，代表登录已失效
    return Vue.$cookies.get('qdi_token')
  }
}

const mutations = {
  SET_STATUS (state, status = 200) {
    state.status = status
  },
  SET_FORM_SEARCH (state, formSearch = '') {
    state.formSearch = formSearch
  },
  SET_IS_SHOW (state, isShow = false) {
    state.isShow = isShow
  },
  SET_IS_MASK (state, isMask = false) {
    state.isMask = isMask
  },
  SET_IS_LOADING (state, isLoading = false) { // 全局loading是否开启
    state.isLoading = isLoading
  },
  SET_IS_LOGIN_ERROR (state, isLoginError = false) { // 是否登录错误
    state.isLoginError = isLoginError
  },
  SET_USER (state, user = undefined) { // 用户
    state.user = user
  },
  SET_HEADER (state, header = undefined) { // 请求头  token  tanentid
    state.header = header
  },
  SET_IS_EXPAND_MENU (state, isExpandMenu = false) { // 是否展开菜单
    state.isExpandMenu = isExpandMenu
  },
  SET_PERMISSION (state, permission = false) { // 权限
    state.permission = permission
  },
  SET_TABS (state, tabs = []) { // 页签
    state.tabs = tabs
  },
  SET_CURR_TAB_ID (state, currTabId = '-1') { // 当前页签ID
    state.currTabId = currTabId
  },
  SET_CURR_ROUTE (state, currRoute = {}) { // 当前路由信息
    state.currRoute = currRoute
  }
}

const actions = {
  // 轻提示
  showToast ({ commit }, { type = 'success', message = '操作成功~', duration = 3 }) {
    Message[type](message, duration)
  },
  showConfirm ({ commit }, { title = '提示', type = 'warning', message = '是否继续？', yesText = '确定', noText = '取消', yes, no }) {
    Modal.confirm({
      title,
      content: message,
      okText: yesText,
      cancelText: noText,
      onOk () {
        if (typeof yes === 'function') {
          yes()
        }
      },
      onCancel () {
        if (typeof no === 'function') {
          no()
        }
      }
    })
  },
  setUser ({ commit }, { user = '' }) {
    commit('SET_USER', user)
  },
  setFormSearch ({ commit }, { formSearch = '' }) {
    commit('SET_FORM_SEARCH', formSearch)
  },
  setHeader ({ commit }, { header = '' }) {
    console.log(header, '请求头')
    commit('SET_HEADER', header)
  },
  async http ({ commit }, { api, headers = {}, query = {}, params = {}, isJson = true, handleError, success, error, complete }) { // 统一请求（简单查询返回使用）
    return await $http[api]({ headers, query, params, isJson, handleError, success, error, complete })
  }
}

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules,
  state,
  getters,
  mutations,
  actions,
  plugins: [createPersistedState({ storage: window.sessionStorage })],
  strict: debug
})
