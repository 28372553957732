export const formatTimestamp = (timestamp, format = 'YYYY-MM-DD HH:mm:ss') => {
  if (!timestamp) {
    return ''
  }
  return moment(timestamp).format(format)
}

export const tranEnum = (key, enumeration, defaultVal) => {
  if (!enumeration) {
    return ''
  }
  const value = enumeration[key]
  if (value) {
    return value
  }
  if (defaultVal !== undefined && defaultVal !== null) {
    return defaultVal
  }
  return key
}

export const yesOrNo = val => {
  switch (val) {
    case 0:
      return '否'
    case 1:
      return '是'
    default:
      return val
  }
}

export const filterContentType = val => {
  switch (val) {
    case 'article':
      return '文章'
    case 'video':
      return '视频'
    case 'audio':
      return '音频'
    default:
      return val
  }
}

export const filterContentViewStyle = val => {
  switch (val) {
    case 'AS11':
      return '文章-标题+1图'
    case 'AS2':
      return '文章-标题+2图'
    case 'AS3':
      return '文章-标题+3图'
    case 'VS1':
      return '视频-标题+1图'
    default:
      return val
  }
}

export const filterContentReleaseStatus = val => {
  switch (val) {
    case 0:
      return '未发布'
    case 1:
      return '已发布'
    case 2:
      return '已下线'
    default:
      return val
  }
}
