const state = {
  componentResources: {},
  componentStyles: {},
  endList: [], // 被选中的组件
  selectIndex: '', // 被选中的下标
  defaultComponentList: [], // 组件默认样式表
  componentStyle: {
    header: { // 头部组件自定义样式
    },
    separateLine: { // 头部组件自定义样式
      bgColor: '#f8f8f8'
    }
  },
  itemsList: {
    basic: [{ // 头部组件
      codeCategory: '',
      enName: '',
      exData: '',
      id: '',
      idPageComponent: '',
      identifier: '',
      title: '',
      imageUrl: '',
      itemType: '',
      linkUrl: '',
      moreText: '',
      name: '',
      sort: 0,
      nameCategory: '',
      status: false
    }],
    twoLayout: [{
      codeCategory: '',
      enName: '',
      exData: '',
      id: '',
      idPageComponent: '',
      identifier: '',
      title: '',
      imageUrl: '',
      itemType: '',
      linkUrl: '',
      moreText: '',
      name: '',
      sort: 0,
      nameCategory: '',
      status: false
    },
    {
      codeCategory: '',
      enName: '',
      exData: '',
      id: '',
      idPageComponent: '',
      identifier: '',
      title: '',
      imageUrl: '',
      itemType: '',
      linkUrl: '',
      moreText: '',
      name: '',
      sort: 1,
      nameCategory: '',
      status: false
    }],
    threeLayout: [{
      codeCategory: '',
      enName: '',
      exData: '',
      id: '',
      idPageComponent: '',
      identifier: '',
      title: '',
      imageUrl: '',
      itemType: '',
      linkUrl: '',
      moreText: '',
      name: '',
      sort: 0,
      nameCategory: '',
      status: false
    },
    {
      codeCategory: '',
      enName: '',
      exData: '',
      id: '',
      idPageComponent: '',
      identifier: '',
      title: '',
      imageUrl: '',
      itemType: '',
      linkUrl: '',
      moreText: '',
      name: '',
      sort: 1,
      nameCategory: '',
      status: false
    }, {
      codeCategory: '',
      enName: '',
      exData: '',
      id: '',
      idPageComponent: '',
      identifier: '',
      title: '',
      imageUrl: '',
      itemType: '',
      linkUrl: '',
      moreText: '',
      name: '',
      sort: 2,
      nameCategory: '',
      status: false
    }
    ]
  }
}

const getters = {}

const mutations = {
  // SET_CMSES (state, cmses = []) { // 例子
  //   state.cmses = cmses
  // },
  SET_COMPONENT_RESOURCES (state, obj) { // 设置首页CMS内容列表
    state.componentResources = obj
  },
  SET_COMPONENT_STYLES (state, obj) { // 设置首页CMS内容列表
    state.componentStyles = obj
  },
  SET_SELECT (state, obj = []) { // 设置首页CMS内容列表
    state.selectIndex = obj.index
  },
  SET_DEFAULT_COMPONET_LIST (state, obj = []) { // 设置首页CMS内容列表
    console.log(obj, 'aaaaaaaaaaaaaaaaaaaaaaaabbbbbbbbbbbjjjjjjjjjjjjj')
    state.defaultComponentList = obj
  },
  SET_LIST (state, obj = []) {
    state.endList = obj
  },
  SET_CHANGELIST (state, obj = {}) {
    console.log('=======', state.selectIndex, state.endList[state.selectIndex])
    state.endList[state.selectIndex] = obj
  }
}

const actions = {
  setComponentResources ({ rootGetters, commit }, obj) {
    commit('SET_COMPONENT_RESOURCES', obj)
  },
  setComponentStyles ({ rootGetters, commit }, obj) {
    commit('SET_COMPONENT_STYLES', obj)
  },
  setSelectIndex ({ rootGetters, commit }, obj) {
    commit('SET_SELECT', obj)
  },
  setDefaultComponentList ({ rootGetters, commit }, obj) {
    commit('SET_DEFAULT_COMPONET_LIST', obj)
  },
  setList ({ rootGetters, commit }, obj) {
    // console.log('bbbbb', obj)
    commit('SET_LIST', obj)
  },
  setChangeList ({ rootGetters, commit }, obj) {
    commit('SET_CHANGELIST', obj)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
