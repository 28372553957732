import store from '@/store'
import cryptoJs from 'crypto-js'

/**
 * 格式化时间戳
 * @param {*} timestamp 时间戳
 * @param {*} format 格式化规则
 * @return 格式化后的时间字符串
 */
export const formatTime = (time, format = 'YYYY-MM-DD HH:mm:ss') => {
  if (!time) {
    return ''
  }
  return moment(time).format(format)
}

/**
 * 复制
 * @param {any} val 值
 */
export const clone = (val) => {
  return JSON.parse(JSON.stringify(val))
}

/**
 * 分配
 * @param {any} data1 值
 * @param {any} data2 值2
 */
export const assign = (data1, data2) => {
  Object.keys(data2).forEach(key => {
    const val = data2[key]
    if (val || val === 0) {
      data1[key] = val
    }
  })
  return data1
}

export const showReLogin = (code, message) => {
  if ([400100, 400101, 400102].includes(code)) {
    if (store.state.isLoginError === true) {
      return
    }
    store.commit('SET_IS_LOGIN_ERROR', true)
    store.dispatch('showConfirm', {
      message,
      confirmButtonText: '去登录',
      confirm: _ => {
        store.commit('SET_IS_LOGIN_ERROR', false)
        store.dispatch('account/logout')
      },
      cancel: _ => {
        store.commit('SET_IS_LOGIN_ERROR', false)
      }
    })
  } else {
    store.commit('SET_IS_LOADING', false)
    store.dispatch('showToast', { type: 'error', message })
  }
}

/**
 * 获取分页序号
 * @param {序号} index
 * @param {分页} pagination
 */
export const getPageIndex = (index, pagination) => {
  return (pagination.pageNumber - 1) * pagination.pageSize + index + 1
}

/**
 * 验证两位小数数值
 * @param {String or Number} val 数值
 * @param {Boolean} allowZero 允许零通过验证
 */
export const validTwoPointNumber = (val = '', allowZero = true) => {
  if (!val && val !== 0) {
    return true
  }
  if (!/^\d+(\.\d+)?$/.test(val)) {
    return false
  }
  val = parseFloat(val)
  if (allowZero) {
    if (/^\d+(\.\d{1,2})?$/.test(val)) {
      return true
    } else {
      return false
    }
  } else {
    if (/^[1-9]+\d?(\.\d{1,2})?$/.test(val)) {
      return true
    } else {
      return false
    }
  }
}

/**
 * 转换数值小数位
 * @param {String or Number} val 数值
 * @param {Boolean} decimal 小数位
 */
export const toFixed = (val, decimal = 2) => {
  if (isNaN(parseFloat(val))) {
    return val
  }
  if (!val && val !== 0) {
    return ''
  } else {
    return val.toFixed(decimal)
  }
}

/**
 * 判断值是否不为空
 * @param {any} val 值
 */
export const isEmpty = (val, isContainBlank = true, isContainZero = false) => {
  if (val) {
    return false
  } else {
    if (!isContainBlank && val === '') {
      return false
    } else if (!isContainZero && val === 0) {
      return false
    }
    return true
  }
}

/**
 * 密码加密
 * @param {string} val 密码
 */
export const encrypt = val => {
  const key = cryptoJs.enc.Latin1.parse('jingyun2022leza1')
  const password = cryptoJs.AES.encrypt(val, key, {
    iv: key,
    mode: cryptoJs.mode.CBC,
    padding: cryptoJs.pad.ZeroPadding
  })
  return password.toString()
}

/**
 * 查找菜单
 * @param {string} menuPath // 菜单路径
 * @param {array} menuTree // 菜单树
 */
export const findMenu = (menuPath, menuTree) => {
  for (let i = 0; i < menuTree.length; i += 1) {
    const item = menuTree[i]
    if (item.children && item.children.length > 0) {
      return findMenu(menuPath, item.children)
    } else {
      if (item.path === menuPath) {
        return item
      }
    }
  }
}

/**
 * 查找路由菜单
 * @param {array} menuTree // 菜单树
 */
export const findRouteMenu = (menuTree) => {
  for (let i = 0; i < menuTree.length; i += 1) {
    const item = menuTree[i]
    if (item.children && item.children.length > 0) {
      return findRouteMenu(item.children)
    } else {
      if (item.path) {
        return item
      }
    }
  }
}

/**
 * 按钮防抖
 *
 */
export const debounce = (fn = () => {}, wait = 1000, triggle = false) => {
  let timeout
  return res => {
    if (timeout) {
      clearTimeout(timeout)
    }
    if (triggle) {
      // 这里如果是真值  就把把点击的第一次实现
      const firstClick = !timeout

      if (firstClick) {
        fn(res)
      }

      timeout = setTimeout(() => {
        timeout = null
      }, wait)
    } else {
      timeout = setTimeout(() => fn(res), wait)
    }
  }
}

export const codeGenerator = (preStr, len) => {
  // Math.random() 生成随机数字, eg: 0.123456
  // .toString(36) 转化成36进制 : "0.4fzyo82mvyr"
  // .slice(-8); 截取最后八位 : "yo82mvyr"
  var str = Math.random().toString(36).slice(-len)
  if (!preStr) {
    preStr = ''
  }
  return preStr + str
}

export const getQueryString = name => {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  var r = window.location.search.substr(1).match(reg)
  if (r != null) {
    return unescape(r[2])
  }
  return null
}

// 向父窗口发送
export const linkTo = name => {
  window.parent.postMessage({
    message: JSON.stringify({ url: name })
  }, '*')
}
