// import VueRouter from 'vue-router'
// import isInIcestark from '@ice/stark-app/lib/isInIcestark'
// import getBasename from '@ice/stark-app/lib/getBasename'
import { isInIcestark, getBasename } from '@ice/stark-app'

import store from '../store'

const ContentList = () => import('@/views/content/list') // 文章管理
const ContentAddEdit = () => import('@/views/content/addOrEdit') // 文章新增修改
const ContentMvAddEdit = () => import('@/views/content/mvAddOrEdit') // 视频类文章新增与修改
const ColumnList = () => import('@/views/column/list') // 栏目列表
const AllocationList = () => import('@/views/allocation/list') // 用户端页面列表
const AllocationAddOrEdit = () => import('@/views/allocation/addOrEdit') // 用户端页面新增
const CarouselList = () => import('@/views/carousel/list') // CMS轮播图列表
const CarouselShopList = () => import('@/views/carousel/shopList') // 电商轮播图列表
const GoodsClassList = () => import('@/views/goodsClass/list') // 商品分类列表
const GoyVirtualList = () => import('@/views/virtual/list') // 虚拟分类列表
const SpecificationsList = () => import('@/views/specifications/list') // 规格设置列表
const Property = () => import('@/views/property/list') // 属性设置列表
const BrandList = () => import('@/views/brand/list') // 品牌列表
const GoodsAddEdit = () => import('@/views/goods/addOrEdit') // 商品发布详情gt
const OnLineList = () => import('@/views/goods/onlineList') // 出售中的商品
const OffLineList = () => import('@/views/goods/offlineList') // 仓库中的商品
const BusinessProperties = () => import('@/views/businessProperties/list') // 商品特性
const BusinessType = () => import('@/views/businessType/list') // 业务类型  virtual：虚拟商品   normal:实物商品
const OrderList = () => import('@/views/order/list') // 订单列表
const OrderDetail = () => import('@/views/order/orderDetail') // 订单详情列表
const FreightTemp = () => import('@/views/order/freightTemp') // 运费模板列表
const FreightTempEdit = () => import('@/views/order/freightTempAddEdit') // 运费模板新增编辑
const SelfLiftingPoint = () => import('@/views/order/selfLiftingPoint') // 自提点列表
const SelfLiftingPointEdit = () => import('@/views/order/selfLiftingPointAddEdit') // 自提点新增编辑
const AfterSaleOrder = () => import('@/views/afterSale/list') // 订单详情列表
const HisAfterSaleOrder = () => import('@/views/afterSale/hisList') // 售后历史
const TenantAddress = () => import('@/views/afterSale/tenantAddress') // 租户退货收货地址
const InvoiceList = () => import('@/views/invoice/list') // 发票列表
const VoucherList = () => import('@/views/vouchers/hisList') // 凭证历史列表页
const VoucherAudit = () => import('@/views/vouchers/waitAuditList') // 凭证待审核列表页
const SceneList = () => import('@/views/scene/list') // 版面管理
const SellerList = () => import('@/views/seller/list') // 销售方管理
const TenantList = () => import('@/views/tenant/list') // 租户列表
const AppList = () => import('@/views/app/list') // 应用列表
const AppMenuList = () => import('@/views/app/appMenuList') // 应用菜单列表
const AppRoleList = () => import('@/views/app/appRoleList') // 应用角色列表
const UserList = () => import('@/views/user/list') // 用户列表
const DictList = () => import('@/views/dict/list') // 字典列表
const DictItemList = () => import('@/views/dict/itemList') // 字典项列表
const ContentEvaluateList = () => import('@/views/evaluate/content/list') // 内容评论管理
const GoodsEvaluateList = () => import('@/views/evaluate/goods/list') // 商品评论管理
const MemberGradeList = () => import('@/views/member/list') // 渠道会员列表
const UserFrontList = () => import('@/views/userFront/list') // 前端用户列表
const SaleAccountList = () => import('@/views/account/sale/list') // 大使账户管理列表
const SaleWithdrawalList = () => import('@/views/account/sale/withdrawalList') // 大使账户提现管理列表
const StageAccountList = () => import('@/views/account/stage/list') // 驿站账户管理列表
const StageWithdrawalList = () => import('@/views/account/stage/withdrawalList') // 驿站账户提现管理列表
const SaleUserList = () => import('@/views/account/saleUserList') // 驿站、大使邀请用户列表
const CommuneAccountList = () => import('@/views/account/commune/list') // 公社账户管理列表
const CommuneSaleAccountList = () => import('@/views/account/commune/saleList') // 公社大使管理列表
const AddStageAccountList = () => import('@/views/account/stageList') // 添加驿站管理列表
const CommuneWithdrawalList = () => import('@/views/account/commune/withdrawalList') // 公社账户提现管理列表
const AccountRecordList = () => import('@/views/account/recordList') // 账户明细列表
const AccountCardList = () => import('@/views/account/cardList') // 账户结算卡列表
const ProCouponList = () => import('@/views/coupon/list') // 优惠券
const ProCouponItemList = () => import('@/views/coupon/itemList') // 优惠券码列表
const ProCouponAdd = () => import('@/views/coupon/add') // 优惠券添加
const ProCouponAddSucess = () => import('@/views/coupon/addSuccess') // 优惠券添加成功
const MeDeviceList = () => import('@/views/device/list') // 机柜列表
const SaleDistributionStatistics = () => import('@/views/sale/distribution/saleStatistics') // 大使分销统计
const StageDistributionStatistics = () => import('@/views/sale/distribution/stageStatistics') // 驿站分销统计
const CommuneDistributionStatistics = () => import('@/views/sale/distribution/communeStatistics') // 驿站分销统计
const SaleStatistics = () => import('@/views/sale/invitation/saleStatistics') // 大使邀请统计
const BeInvitedSaleList = () => import('@/views/sale/invitation/beInvitedSaleList') // 被邀请大使列表
const BeInvitedStageList = () => import('@/views/sale/invitation/beInvitedStageList') // 被邀请驿站列表
const StageStatistics = () => import('@/views/sale/invitation/stageStatistics') // 驿站邀请同国际
const saleAward = () => import('@/views/sale/config/saleAward') // 分销大使奖励设置
const stageAward = () => import('@/views/sale/config/stageAward') // 分销驿站奖励设置
const communeAward = () => import('@/views/sale/config/communeAward') // 分销公社奖励设置
const publicity = () => import('@/views/sale/config/publicity') // 分销宣传关系设置
const signer = () => import('@/views/sale/config/signer') // 分销销售关系设置
const withdrawal = () => import('@/views/sale/config/withdrawal') // 账户提现最新金额设置
const distributor = () => import('@/views/distribution/distributor') // 分销员管理
const team = () => import('@/views/distribution/team') // 团队管理
const GoodsKeyword = () => import('@/views/keyword/list') // 关键字管理
const Advertising = () => import('@/views/advertising/list') // 广告管理

const routes = [

  { path: '/content/list', name: 'contentList', component: ContentList },
  { path: '/content/addEdit', name: 'contentAddEdit', component: ContentAddEdit },
  { path: '/content/mvEdit', name: 'ContentMvAddEdit', component: ContentMvAddEdit },
  { path: '/column/list', name: 'columnList', component: ColumnList },
  { path: '/allocation/addOrEdit', name: 'allocationAddOrEdit', component: AllocationAddOrEdit },
  { path: '/allocation/list', name: 'allocationList', component: AllocationList },
  { path: '/carousel/list', name: 'CarouselList', component: CarouselList },
  { path: '/carousel/shopList', name: 'CarouselShopList', component: CarouselShopList },
  { path: '/goodsClass/list', name: 'GoodsClass', component: GoodsClassList },
  { path: '/virtual/list', name: 'GoyVirtualList', component: GoyVirtualList },
  { path: '/specifications/list', name: 'SpecificationsList', component: SpecificationsList },
  { path: '/property/list', name: 'Property', component: Property },
  { path: '/brand/list', name: 'BrandList', component: BrandList },
  { path: '/goods/addOrEdit', name: 'GoodsAddEdit', component: GoodsAddEdit },
  { path: '/goods/onlineList', name: 'OnLineList', component: OnLineList },
  { path: '/goods/offlineList', name: 'OffLineList', component: OffLineList },
  { path: '/businessProperties/list', name: 'businessProperties', component: BusinessProperties },
  { path: '/businessType/list', name: 'businessType', component: BusinessType },
  { path: '/order/list', name: 'OrderList', component: OrderList },
  { path: '/order/orderDetail', name: 'OrderDetail', component: OrderDetail },
  { path: '/order/freightTemp', name: 'FreightTemp', component: FreightTemp },
  { path: '/order/freightTempEdit', name: 'FreightTempEdit', component: FreightTempEdit },
  { path: '/order/selfLiftingPoint', name: 'SelfLiftingPoint', component: SelfLiftingPoint },
  { path: '/order/selfLiftingPointEdit', name: 'SelfLiftingPointEdit', component: SelfLiftingPointEdit },
  { path: '/afterSale/list', name: 'AfterSaleOrder', component: AfterSaleOrder },
  { path: '/afterSale/hisList', name: 'HisAfterSaleOrder', component: HisAfterSaleOrder },
  { path: '/afterSale/tenantAddress', name: 'TenantAddress', component: TenantAddress },
  { path: '/invoice/list', name: 'InvoiceList', component: InvoiceList },
  { path: '/vouchers/hisList', name: 'VoucherList', component: VoucherList },
  { path: '/vouchers/waitAuditList', name: 'VoucherAudit', component: VoucherAudit },
  { path: '/scene/list', name: 'sceneList', component: SceneList },
  { path: '/seller/list', name: 'sellerList', component: SellerList },
  { path: '/tenant/list', name: 'TenantList', component: TenantList },
  { path: '/app/list', name: 'appList', component: AppList },
  { path: '/app/appMenuList', name: 'AppMenuList', component: AppMenuList },
  { path: '/app/appRoleList', name: 'AppRoleList', component: AppRoleList },
  { path: '/user/list', name: 'userList', component: UserList },
  { path: '/dict/list', name: 'dictList', component: DictList },
  { path: '/dict/:id/item/list', name: 'dictItemList', component: DictItemList, meta: { selectMenuPath: '/dict/list' } },
  { path: '/evaluate/content/list', name: 'ContentEvaluateList', component: ContentEvaluateList },
  { path: '/evaluate/goods/list', name: 'GoodsEvaluateList', component: GoodsEvaluateList },
  { path: '/member/list', name: 'MemberGradeList', component: MemberGradeList },
  { path: '/userFront/list', name: 'UserFrontList', component: UserFrontList },
  { path: '/account/sale/list', name: 'SaleAccountList', component: SaleAccountList },
  { path: '/account/sale/withdrawalList', name: 'SaleWithdrawalList', component: SaleWithdrawalList },
  { path: '/account/stage/list', name: 'StageAccountList', component: StageAccountList },
  { path: '/account/stage/withdrawalList', name: 'StageWithdrawalList', component: StageWithdrawalList },
  { path: '/account/saleUserList', name: 'SaleUserList', component: SaleUserList },
  { path: '/account/commune/list', name: 'CommuneAccountList', component: CommuneAccountList },
  { path: '/account/commune/saleList', name: 'CommuneSaleAccountList', component: CommuneSaleAccountList },
  { path: '/account/stageList', name: 'AddStageAccountList', component: AddStageAccountList },
  { path: '/account/commune/withdrawalList', name: 'CommuneWithdrawalList', component: CommuneWithdrawalList },
  { path: '/account/recordList', name: 'AccountRecordList', component: AccountRecordList },
  { path: '/account/cardList', name: 'AccountCardList', component: AccountCardList },
  { path: '/coupon/list', name: 'ProCouponList', component: ProCouponList },
  { path: '/coupon/itemList', name: 'ProCouponItemList', component: ProCouponItemList },
  { path: '/coupon/add', name: 'ProCouponAdd', component: ProCouponAdd },
  { path: '/coupon/addSuccess', name: 'ProCouponAddSuccess', component: ProCouponAddSucess },
  { path: '/device/list', name: 'MeDeviceList', component: MeDeviceList },
  { path: '/sale/distribution/saleStatistics', name: 'SaleDistributionStatistics', component: SaleDistributionStatistics },
  { path: '/sale/distribution/stageStatistics', name: 'StageDistributionStatistics', component: StageDistributionStatistics },
  { path: '/sale/distribution/communeStatistics', name: 'CommuneDistributionStatistics', component: CommuneDistributionStatistics },
  { path: '/sale/invitation/saleStatistics', name: 'SaleStatistics', component: SaleStatistics },
  { path: '/sale/invitation/beInvitedSaleList', name: 'BeInvitedSaleList', component: BeInvitedSaleList },
  { path: '/sale/invitation/beInvitedStageList', name: 'BeInvitedStageList', component: BeInvitedStageList },
  { path: '/sale/invitation/stageStatistics', name: 'StageStatistics', component: StageStatistics },
  { path: '/sale/config/saleAward', name: 'saleAward', component: saleAward },
  { path: '/sale/config/stageAward', name: 'stageAward', component: stageAward },
  { path: '/sale/config/communeAward', name: 'communeAward', component: communeAward },
  { path: '/sale/config/withdrawal', name: 'withdrawal', component: withdrawal },
  { path: '/sale/config/publicity', name: 'publicity', component: publicity },
  { path: '/sale/config/signer', name: 'signer', component: signer },
  { path: '/distribution/distributor', name: 'distributor', component: distributor },
  { path: '/distribution/team', name: 'team', component: team },
  { path: '/keyword/list', name: 'GoodsKeyword', component: GoodsKeyword },
  { path: '/advertising/list', name: 'Advertising', component: Advertising }

]

const router = new VueRouter({
  base: isInIcestark() ? getBasename() : '/',
  mode: 'history',
  routes
})

// 全局前置守卫
router.beforeEach((to, from, next) => {
  const meta = to.meta
  meta.layout = meta.layout || 'default'
  let title = meta.title
  if (!title) {
    title = store.state.title
  }
  setTimeout(() => {
    document.title = title
  }, 0)
  next()
})

// 全局解析守卫
router.beforeResolve((to, from, next) => {
  next()
})

// 全局后置钩子
router.afterEach((to, from) => {
  setTimeout(_ => {
    store.commit('SET_IS_SHOW', true)
  }, 300)
})

// export default routes
export default router
