// 是否
import * as apis from '@/utils/apis'

// 图片上传地址
export const uploadFileUrl = process.env.VUE_APP_GATEWAY + apis.uploadFile.url

export const YES_OR_NO = {
  0: '否',
  1: '是'
}

// 初始化菜单
export const InitMenu = {
  parentCode: '', // 父编码
  id: '', // 权限ID
  code: '', // 权限编码
  name: '', // 权限名称
  path: '', // 权限路径
  type: '0', // 权限类型
  controlCode: '', // 按钮编码
  layer: '', // 权限层级
  paramDTO: { // 图标
    icon: ''
  }
}

// 正则表达式
// 手机号正则
export const phoneReg = /^1[0-9]\d{9}$/
// 邮箱正则
export const emailReg = /^([a-zA-Z0-9]+[_|_|\-|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,6}$/
