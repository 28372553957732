import axios from 'axios'
import qs from 'qs'
import * as apis from './apis'
import store from '@/store'
import { showReLogin, getQueryString } from '@/utils'

(function (global, factory) {
  if (typeof exports === 'object' && typeof module !== 'undefined') {
    module.exports = factory()
  } else {
    if (typeof define === 'function' && define.amd) {
      define(factory)
    } else {
      global = global || self
      global.$http = factory()
    }
  }
}(this, function () {
  'use strict'
  const instance = axios.create({
    timeout: 20000,
    withCredentials: false
  })

  instance.interceptors.request.use(config => {
    const query = config.query
    if (Object.keys(query).length > 0) {
      Object.keys(query).forEach(key => {
        config.url = config.url.replace(new RegExp(`{${key}}`, 'g'), query[key])
      })
    }
    // const { tokenType, tokenValue, currentTenantId } = store.state.user
    const tokenType = getQueryString('tokenType') || store.state.user.tokenType
    const tokenValue = getQueryString('tokenValue') || store.state.user.tokenValue
    const currentTenantId = getQueryString('currentTenantId') || store.state.user.currentTenantId
    if (tokenType && tokenValue) {
      config.headers.Authorization = `${tokenType} ${tokenValue}`
      config.headers.tenantId = currentTenantId
    }
    if (!['get', 'delete', 'head', 'options'].includes(config.method) && config.isJson === false) {
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
      config.data = qs.stringify(config.data)
      return config
    }
    return config
  }, error => {
    return Promise.reject(error)
  })

  instance.interceptors.response.use(({ config, data = { code: 500 }, headers, status }) => {
    // eslint-disable-next-line eqeqeq
    if (data.ret !== undefined && data.ret == 0) {
      data.code = 200
    }
    // handleError 是否统一处理错误
    if (config.handleError) {
      if (status > 200 && status < 300) {
        return `HttpStatus:${status}`
      }
      if (data.code === 200) {
        return data.result
      } else {
        const message = data.msg || '接口请求错误'
        showReLogin(data.code, message)
        return Promise.reject(message)
      }
    } else {
      return data
    }
  }, error => {
    if (error.response && error.response.status && error.response.status === 401) {
      store.dispatch('showToast', { type: 'error', message: '登入失效，请重新登入' })

      //   给主应用发送 登录失效消息
      window.parent.postMessage({
        message: JSON.stringify({ auth: '401' })
      }, '*')
      return Promise.reject(error)
    }
    // if (instance.isCancel(error)) { // 主动取消
    if (error.code === 'ECONNABORTED') { // 网络请求超时，请求被终止
      store.dispatch('showToast', { type: 'error', message: '网络请求超时，请稍后重试' })
    } else { // 其他异常
      store.dispatch('showToast', { type: 'error', message: '接口请求异常，请稍后重试' })
    }
    return Promise.reject(error)
  })

  const $http = {}

  Object.keys(apis).forEach(apiKey => {
    const api = apis[apiKey]
    const apiMethod = api.method || 'get'
    const apiUrl = api.url
    $http[apiKey] = async ({
      headers = {},
      params = {},
      query = {},
      isJson = true,
      handleError = true,
      handleLoading = true,
      success = () => {},
      error = () => {},
      complete = () => {}
    } = {}) => {
      let rsp = {}
      if (['get', 'delete', 'head', 'options'].includes(apiMethod)) {
        try {
          rsp = await instance[apiMethod](process.env.VUE_APP_GATEWAY + apiUrl, { headers, params, query, isJson, handleError, handleLoading })
          if (typeof success === 'function') {
            success()
          }
        } catch (error) {
          if (handleError && handleLoading) {
            store.commit('SET_IS_LOADING', false)
            throw new Error(error)
          }
          if (typeof error === 'function') {
            error()
          }
        } finally {
          if (typeof complete === 'function') {
            complete()
          }
        }
      } else {
        try {
          rsp = await instance[apiMethod](process.env.VUE_APP_GATEWAY + apiUrl, params, { headers, query, isJson, handleError, handleLoading })
          if (typeof success === 'function') {
            success()
          }
        } catch (error) {
          if (handleError && handleLoading) {
            store.commit('SET_IS_LOADING', false)
            throw new Error(error)
          }
          if (typeof error === 'function') {
            error()
          }
        } finally {
          if (typeof complete === 'function') {
            complete()
          }
        }
      }
      return rsp
    }
  })
  return $http
}))
