// 通用方法
import * as utils from '@/utils'

// 常量
import * as consts from '@/utils/constants'

// 枚举
import * as enums from '@/utils/enums'

// 过滤器
import * as filters from '@/utils/filters'

// HTTP
import '@/utils/http'

// 配置项
const processEnv = process.env
const $config = {}
Object.keys(processEnv).forEach(key => {
  if (key.indexOf('VUE_APP_') === 0) {
    const newKey = key.substring(8)
    $config[newKey] = processEnv[key]
  }
})
Vue.prototype.$config = $config

// 通用方法
const $utils = {}
Object.keys(utils).forEach((key) => {
  $utils[key] = utils[key]
})
Vue.prototype.$utils = $utils

// 常量
const $consts = {}
Object.keys(consts).forEach((key) => {
  $consts[key] = consts[key]
})
Vue.prototype.$consts = $consts

// 枚举
const $enums = {}
Object.keys(enums).forEach(key => {
  $enums[key] = Object.freeze(enums[key])
})
Vue.prototype.$enums = Object.freeze($enums)

// 过滤器
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key])
})
