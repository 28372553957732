
const state = {
  virtualList: [], // 商品虚拟分类
  goodsSelectedKeys: [],
  //   goodsSelected: [],
  goodsGroupSelectedKeys: []
//   goodsGroupSelected: []
}

const getters = {
}

const mutations = {
  // 选中的商品
  SET_GOODS_SELECTED (state, goodsSelectedKeys = []) {
    state.goodsSelectedKeys = goodsSelectedKeys
  },
  //   选中的商品类型
  SET_GOODS_GROUP_SELECTED (state, goodsGroupSelectedKeys = []) {
    state.goodsGroupSelectedKeys = goodsGroupSelectedKeys
  },
  //   清掉选中的商品分组 或者 商品
  CLEAR_SELECTED_KEYS (state) {
    state.goodsSelectedKeys = []
    state.goodsGroupSelectedKeys = []
  },
  //   清掉选中的商品分组 或者 商品
  SET_GOODS_VIRTUAL_LIST (state, list = []) {
    state.virtualList = list
  },
  DELETE_KEY_IN_GOODS (state, key = '') {
    let newKyes = []
    state.goodsSelectedKeys.forEach((element) => {
      if (key !== element) {
        newKyes.push(element)
      }
    })
    state.goodsSelectedKeys = newKyes
  },
  DELETE_KEY_IN_GOODS_GROUP (state, key = '') {
    let newKyes = []
    state.goodsGroupSelectedKeys.forEach((element) => {
      if (key !== element) {
        newKyes.push(element)
      }
    })
    state.goodsGroupSelectedKeys = newKyes
  }
}

const actions = {
  // 获取渠道
  async getTeamList ({ rootGetters }) {
    const res = await rootGetters.http.teamList()
    if (res.records && res.records.length > 0) {
      return res.records
    }
    return res
  },
  // 获取商品分组
  async goodsVirtualList ({ rootGetters, commit }) {
    const res = await rootGetters.http.goodsGroupForCoupon()
    if (res.records && res.records.length > 0) {
      commit('SET_GOODS_VIRTUAL_LIST', res.records)
      return res.records
    }
    commit('SET_GOODS_VIRTUAL_LIST', [])
    return res
  },
  // 获取商品by ids
  async goodsByIds ({ rootGetters, commit, state }) {
    const res = await rootGetters.http.goodsForCoupon({
      params: { productIds: state.goodsSelectedKeys, onsell: 1 }
    })
    console.log(res, '能过ID获取')
  },
  // 获取商品by ids
  async getCouponDetail ({ rootGetters, commit, state }, id = '') {
    const res = await rootGetters.http.getCouponDetail({
      query: { id }
    })
    return res
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
